<template>
  <div class="view-blog container py-5">
    <h2 class="font-serif mb-3 text-center">Reflexões</h2>

    <action-dispatcher
      action="blog/getCategories"
      class="categories mb-4 gap-4"
    >
      <router-link
        :to="{ query: {} }"
        :class="{ active: !$route.query.category }"
      >
        Tudo
      </router-link>
      <router-link
        :to="{ query: { category: category.id } }"
        v-for="category in categories"
        :key="category.id"
        :class="{ active: $route.query.category === '' + category.id }"
      >
        {{ category.name }}
      </router-link>
    </action-dispatcher>

    <action-dispatcher
      action="blog/getAll"
      class="row justify-content-center g-4"
      :parameters="parameters"
    >
      <router-link
        v-for="post in posts"
        :key="post.id"
        :to="{ name: 'blog-post', params: { id: post.id, slug: post.slug } }"
        class="col-lg-4 col-md-6 text-decoration-none text-light"
      >
        <div class="position-relative">
          <div class="ratio ratio-16x9 mb-3">
            <img :src="post.cover" class="img-fluid" />
          </div>
          <span
            class="badge bg-primary text-dark position-absolute bottom-0 end-0 m-2"
            >{{ post.category.name }}</span
          >
        </div>
        <h3 class="font-serif mb-0">{{ post.title }}</h3>
        <div class="text-secondary small mb-2">
          {{ post.author.name }}, {{ date(post.published_at) }}
        </div>
        <div v-html="post.headline"></div>
      </router-link>
    </action-dispatcher>

    <pagination
      v-model="page"
      :count="count"
      :limit="parameters.limit"
      class="mt-5"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      page: parseInt(this.$route.query.page || 1),
    };
  },
  computed: {
    ...mapState("blog", ["posts", "categories", "count"]),
    parameters() {
      const limit = 9;
      return {
        ...this.$route.query,
        limit,
        offset: limit * (this.page - 1),
      };
    },
  },
  watch: {
    page() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      });
    },
  },
  methods: {
    date(date) {
      return new Date(date).toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-blog {
  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 14px;

      &.active {
        background-color: #deb34d;
        padding: 2px 8px;
        border-radius: 100px;
        color: black;
        font-weight: bold;
      }
    }
  }
}
</style>
